import { apiCall } from "../../api/index";
import { baseUrl } from "../../contant";
import { ABOUT_RESPONSE, WHY_RESPONSE, CONTACT_FORM_RESPONSE, HOME_RESPONSE, OWNERS_SAYING_RESPONSE, SEARCH_RESPONSE ,PRIVACY_TERMS_RESPONSE} from "../action/type";

export const about_dispatch = (successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "post",
        url: `${baseUrl}appdata/about_us`,
    };
    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
        // console.log("res",response.data)
    } else {
        failureCallback(response?.data)
        // console.log("res",response.data)
    }
    dispatch({
        type: ABOUT_RESPONSE,
        payload: response?.data,
    });
};

export const why_dispatch = (successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "get",
        url: `${baseUrl}appdata/get_why_a1_truck_parking_data`,
    };
    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
        // console.log("res",response.data)
    } else {
        failureCallback(response?.data)
        // console.log("res",response.data)
    }
    dispatch({
        type: WHY_RESPONSE,
        payload: response?.data,
    });
};

export const contact_dispatch = (data, successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "post",
        url: `${baseUrl}appdata/contact_us`,
        data,
    };

    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
        // console.log("res",response.data)
    } else {
        failureCallback(response?.data)
        // console.log("res",response.data)
    }
    dispatch({
        type: CONTACT_FORM_RESPONSE,
        payload: response?.data,
    });
};

export const home_dispatch = (successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "get",
        url: `${baseUrl}appdata/get_landing_page_video`,

    };

    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
        // console.log("res",response.data)
    } else {
        failureCallback(response?.data)
        // console.log("res",response.data)
    }
    dispatch({
        type: HOME_RESPONSE,
        payload: response?.data,
    });
};

export const owners_dispatch = (successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "get",
        url: `${baseUrl}appdata/get_owners_saying_data`,
    };
    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
        // console.log("res",response.data)
    } else {
        failureCallback(response?.data)
        // console.log("res",response.data)
    }
    dispatch({
        type: OWNERS_SAYING_RESPONSE,
        payload: response?.data,
    });
};
export const search_dispatch = (successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "get",
        url: `${baseUrl}appdata/services_and_amenities`,

    };
    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
        // console.log("res",response.data)
    } else {
        failureCallback(response?.data)
        // console.log("res",response.data)
    }
    dispatch({
        type: SEARCH_RESPONSE,
        payload: response?.data,
    });
};
export const privacy_dispatch = (successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "get",
        url: `${baseUrl}admin/terms`,

    };
    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
        // console.log("res",response.data)
    } else {
        failureCallback(response?.data)
        // console.log("res",response.data)
    }
    dispatch({
        type: PRIVACY_TERMS_RESPONSE,
        payload: response?.data,
    });
};


