import logo from "../src/images/logo.png"
import play from "../src/images/playbutton.png"
import store from "./images/appstore.png"
import download from "./images/button.png"
import aboutImage from "./images/blueTruck.png"
import banner from "./images/iphone.png"
import contactImage from "./images/group.png"
import rectangle from "./images/rect.png"
import solidappmaker from "./images/solidappmaker.png"
import { useEffect } from 'react';
import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { about_dispatch, why_dispatch, contact_dispatch, home_dispatch, owners_dispatch, search_dispatch } from "./redux/action/landingPage"
import { toast } from "react-toastify";
import missionText from "./images/blue.png"
import ReactPlayer from "react-player";

// import { baseUrl } from "./contant"

function LandingPage() {

    // const fileUrl = "https://d2j4i4yk9jv8d.cloudfront.net"
    const fileUrl = "https://d2j4i4yk9jv8d.cloudfront.net/"

    const [lastClickedLink, setLastClickedLink] = useState(null);

    const dispatch = useDispatch()
    const FetchData = useSelector((state) => state?.data)
    console.log("FetchData", FetchData.home_data?.[0].video_link)

    const whyData = useSelector((state) => state?.data?.why_data?.[0]?.why_truck_parking_data?.why_data)
    // console.log("data",whyData)
    const [contact, setContact] = useState({
        name: "",
        email: "",
        message: "",
    })

    const handlevalue = (e, key) => {
        setContact({ ...contact, [key]: e.target.value })
    }

    const handleContact = () => {
        toast.dismiss();
        const emailRegex = /\S+@\S+\.\S+/;
        // check if email input matches email format
        if (contact.name === "") {
            toast.error("Please enter your name");
            console.log("name rqyuired")
            return;
        }
        if (contact.email === "") {
            toast.error("Please enter your email id");
            console.log("email rqyuired")
            return;
        }
        if (contact.message === "") {
            toast.error("Please type message");
            console.log("msg rqyuired")
            return;
        }

        if (!emailRegex.test(contact.email)) {
            // display error message or set error state
            toast.error("Please enter a valid email address.");
            console.log("Please enter a valid email address.");
            return;
        }

        const payload = {
            name: contact.name,
            email: contact.email,
            message: contact.message,
        }

        const successCb = (res) => {
            console.log("succCbMyProfile", res)
            toast.success("Contact form submitted successfully.");
            setContact({
                name: "",
                email: "",
                message: "",
            });
        }
        const failureCb = (res) => {
            console.log("failCbMyprfile", res)
            toast.error(res?.message)
        }

        dispatch(contact_dispatch(payload, successCb, failureCb))
    }

    const about_data = () => {
        const successCb = (res) => {
            // console.log("succCb", res)
        }
        const failureCb = (res) => {
            // console.log("failCbMyprfile", res)
        }
        dispatch(about_dispatch(successCb, failureCb))
    }

    const why_data = () => {

        const successCb = (res) => {
            // console.log("succCb", res)
        }
        const failureCb = (res) => {
            // console.log("failCb", res)
        }
        dispatch(why_dispatch(successCb, failureCb))
    }

    const home_data = () => {

        const successCb = (res) => {
            // console.log("succCb", res)
        }
        const failureCb = (res) => {
            // console.log("failCb", res)
        }
        dispatch(home_dispatch(successCb, failureCb))
    }

    const owners_saying_data = () => {

        const successCb = (res) => {
            // console.log("succCb", res)
        }
        const failureCb = (res) => {
            // console.log("failCb", res)
        }
        dispatch(owners_dispatch(successCb, failureCb))
    }

    const search_data = () => {

        const successCb = (res) => {
            // console.log("succCb", res)
        }
        const failureCb = (res) => {
            // console.log("failCb", res)
        }
        dispatch(search_dispatch(successCb, failureCb))
    }


    useEffect(() => {
        about_data()
        why_data()
        home_data()
        owners_saying_data()
        search_data()
    }, [])

    const changesPage = localStorage.getItem('changesPage');
    useEffect(() => {
        const element = document.getElementById(changesPage);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [changesPage])
    const googleplay = () => {

    }
    const apple = () => {

    }
    return (
        <>
            {/* navbar */}
            <nav class="navbar navbar-expand-md bg-white navbar-dark">
                <div class="container">

                    <a class="navbar-brand" >
                        <div className="logo">
                            <img src={logo} className="responsive"></img>
                        </div>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end " id="collapsibleNavbar">

                        <ul className="navbar-nav">
                            <li className={`nav-item mx-3 ${!lastClickedLink || lastClickedLink === 'home' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="#home"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { setLastClickedLink('home'); window.location.hash = "home"; }}
                                >
                                    Home
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'about' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="#MissionStatement"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { setLastClickedLink('about'); window.location.hash = "about"; }}
                                >
                                    Mission Statement
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'why' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="#why"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { setLastClickedLink('why'); window.location.hash = "why"; }}
                                >
                                    Why Us
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'Services' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="#Services"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { setLastClickedLink('Services'); window.location.hash = "Services"; }}
                                >
                                    Services
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'contact' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="#contact"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { setLastClickedLink('contact'); window.location.hash = "contact"; }}
                                >
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* first container */}
            <div className="hs" id="home">
                <div class="videocontainer">
                    {
                        FetchData.home_data?.[0].video_link?.video && (

                            <video
                                src={`${fileUrl}${FetchData.home_data?.[0].video_link?.video}`}
                                class="videoshow"
                                type="video/mp4"
                                autoPlay={true} loop muted>
                            </video>
                        )
                    }
                    {
                        FetchData.home_data?.[0].video_link?.youtube && (
                            <ReactPlayer
                                url={FetchData.home_data?.[0].video_link?.youtube}
                                playing={true}
                                autoplay={true}
                                width={"100%"}
                                height={"100%"}
                                class="videoshow"
                                muted={false}
                                loop={true}
                            />
                        )
                    }

                    {/* <div class="text-overlay text-center">
                        <h6 className="heading">Find your Parking space</h6>
                        <h3 className="text-white h3content"> Ready to find the Right Parking Space?</h3>
                        <div className="appButton d-flex justify-content-center ">
                            <a href="https://play.google.com/store/apps/details?id=com.a1_trucks" target="_blank">
                                <div className="play" >
                                    <img src={play}></img>
                                </div>
                            </a>
                            <a href="https://apps.apple.com/in/app/a1-truck-parking/id6449964581" target="_blank">
                                <div className="play" >
                                    <img src={store}></img>
                                </div>
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* second   container */}
            <div id="about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-center ">
                            <div className="container paddingMision">
                                <div className="about_heading">
                                    <h6 className="about heading_why">Our  <span> Mission Statement</span></h6>
                                    <p className="p content_why" dangerouslySetInnerHTML={{ __html: FetchData.about_data?.[0]?.about_truck_parking?.description }} >

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-5">
                            <div className="about_truck">
                                <img src={aboutImage} class="responsive"></img>
                                {/* <video src={missionVideo} class="videoshowMission" type="video/mp4" autoPlay={true} loop muted></video> */}
                            </div>
                        </div>
                    </div>
                    <div className="parentMission">
                        <div className="missionImg">
                            <img src={missionText} class="responsive"></img>
                        </div>
                        <div className="content">
                            <p className="spaceing" dangerouslySetInnerHTML={{ __html: FetchData.owners_saying_data?.[0].owners_saying }} >
                            </p>

                            {/* <p>
                            {FetchData.owners_saying_data?.[0].owners_saying    }
                        </p> */}
                        </div>
                    </div>

                </div>
            </div>
            {/* third container */}
            <div className="About" >
                <div className="container padding_why about" id="why">
                    <h6 className="text-center heading_why ">Why <span>A1 Truck Parking?</span></h6>
                    <div className="row marginMission">
                        {
                            whyData?.map((imgData) => {
                                return (
                                    <div className="col-md-4">
                                        <div class="card cardstyle p-3">
                                            <img class="card-img-top"
                                                // src={truck3} 
                                                src={`${fileUrl}${imgData?.a1_image}`}
                                                alt="Card image cap" />
                                            <div class="card-body">
                                                <p class="card-text">{imgData?.caption}</p>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {/* services */}
            <div className="container-fluid padding_why  services" id="Services">
                <div className="cardshadow padding_why marginservices">
                    <h6 className="text-center heading_why">Services and <span>Amenities</span> </h6>
                    <div className="row padding_why paddingless">

                        <div className="row services">
                            {FetchData.search_amenities_data?.[0]?.services_and_amenities?.map((data) => {
                                return (
                                    <div className="col-md-6 amenties">
                                        <div className="d-flex">
                                            <div className="l_img">
                                                <img
                                                    //  src={l1}
                                                    src={`${fileUrl}${data?.icon}`}
                                                ></img>
                                            </div>
                                            <p className="px-3 content_why" >{data?.content}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* fourth contaier */}

            <div className="container padding_why">
                <h6 className="text-center heading_why">Download <span>A1 Truck Parking</span> App!</h6>
                <h6 className="text-center download_h6">
                    Ready to find the Right Parking Space?
                </h6>
                <br></br>
                <div className="appButton d-flex justify-content-center flex-wrap">
                <a href="https://play.google.com/store/apps/details?id=com.a1_trucks" target="_blank">
                    <div className="play">
                        <img src={play}></img>
                    </div>
                    </a>
                    <a href="https://apps.apple.com/in/app/a1-truck-parking/id6449964581" target="_blank">
                    <div className="play">
                        <img src={store}></img>
                    </div>
                    </a>
                </div>
                <div className="image_banner">
                    <img src={banner}></img>
                </div>
            </div>

            {/* fifth container */}
            <div className="About" id="contact">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="container paddind_contact_heading">
                                <div className="about_heading">
                                    <h6 className="heading_why contact textCenter">Contact  <span> Us!</span></h6>
                                    {/* <p className=" content_why">Lorem et accusre et dolore magna aliquyam erat, sed diam voluptua. At vero eos  sed diam voluptua. At vero eo sed diam voluptua. At vero eo et accusam et justo duo dolores et ea rebum.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row form_bottom">
                        <div className="col-md-7">
                            <div className="container paddind_contact">
                                <form className="input">
                                    <div class="form-group my-3">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name*" value={contact?.name} onChange={(e) => handlevalue(e, 'name')} />
                                    </div>
                                    <div class="form-group my-3">
                                        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="E-Mail*"
                                            value={contact?.email}
                                            onChange={(e) => handlevalue(e, 'email')} />
                                    </div>
                                    <div class="form-group my-3">
                                        <textarea class="form-control" placeholder="Message*" id="exampleFormControlTextarea1" rows="6" value={contact?.message} onChange={(e) => handlevalue(e, 'message')}></textarea>
                                    </div>
                                </form>
                                <div className="contactbutton " onClick={handleContact}>
                                    <span >Send</span>
                                </div>
                                {/* <div className="contact">
                                    <div className="dowmload_button" onClick={handleContact}>
                                        <img src={download}>
                                        </img>
                                    </div>
                                    <div className="conatct_text">
                                        <p onClick={handleContact}>Send</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="about_truck">
                                <img src={contactImage}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer */}
            <div className="container">
                <div className="footerimg">
                    <img src={logo}></img>
                </div>
                <div className="row nav_bottom">
                    <div className="col-md-1">
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link " href="#home">Home</a> */}
                            <Link
                                className="nav-link"
                                to="#home"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { setLastClickedLink('home'); window.location.hash = "home"; }}
                            >
                                Home
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link" href="#MissionStatement">  Mission Statement</a> */}
                            <Link
                                className="nav-link"
                                to="#MissionStatement"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { setLastClickedLink('about'); window.location.hash = "about"; }}
                            >
                                Mission Statement
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link" href="#why">Why Us</a> */}
                            <Link
                                className="nav-link"
                                to="#why"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { setLastClickedLink('why'); window.location.hash = "why"; }}
                            >
                                Why Us
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link" href="#Services">Services</a> */}
                            <Link
                                className="nav-link"
                                to="#Services"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { setLastClickedLink('Services'); window.location.hash = "Services"; }}
                            >
                                Services
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2 contactspace">
                        <div className="footer">
                            {/* <a class="nav-link" href="#contact">Contact Us</a> */}
                            <Link
                                className="nav-link"
                                to="#contact"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { setLastClickedLink('contact'); window.location.hash = "contact"; }}
                            >
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="parent_footer">

                <div className="footer_text">
                    <div className="d-flex justify-content-center py-3"> © 2023 - All Rights Reserved - A1 Truck Parking  | &nbsp;<Link to="/PrivacyPolicy" className="text-white" > Privacy Policy </Link >&nbsp; and  &nbsp;<Link className="text-white" to="/TermsConditions" > Terms of Use</Link>&nbsp; | Designed & Developed by
                        &nbsp;<div className="solidappmaker" >
                            <a href="https://solidappmaker.com/" target="_blank">
                                <img src={solidappmaker}></img></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LandingPage


