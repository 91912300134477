import { ABOUT_RESPONSE, WHY_RESPONSE, CONTACT_FORM_RESPONSE, HOME_RESPONSE, OWNERS_SAYING_RESPONSE, SEARCH_RESPONSE ,PRIVACY_TERMS_RESPONSE} from "../action/type";

const Initial_state = {
    about_data: null,
    why_data: null,
    contact_data: null,
    home_data: null,
    owners_saying_data: null,
    search_amenities_data: null,
    privacy_terms_data:null
}

export default (state = Initial_state, action) => {
    const { type, payload } = action;
    switch (type) {
        case ABOUT_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    about_data: payload?.data
                }
            };
            
        case WHY_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    why_data: payload?.data
                }
            };

        case CONTACT_FORM_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    contact_data: payload?.data
                }
            };

        case HOME_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    home_data: payload?.data
                }
            };

        case OWNERS_SAYING_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    owners_saying_data: payload?.data
                }
            };

        case SEARCH_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    search_amenities_data: payload?.data
                }
            };
        case PRIVACY_TERMS_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    privacy_terms_data: payload?.data
                }
            };

        default:
            return state;
    }
}