// import logo from './logo.svg';
// import './App.css';
// import './style.css'
// import LandingPage from "../src/landingpage"
// import { Provider } from 'react-redux';
// import store from './store';
// // import { PersistGate } from 'redux-persist/integration/react';
// // import { store, persistor } from './store';

// function App() {
//   return (
//     <Provider store={store}>
//     <LandingPage/>
//     </Provider>
//   );
// }

// export default App;

import logo from './logo.svg';
import './App.css';
import './style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { HashRouter } from 'react-router-dom';
import AppRouter from '../src/routes/index';



function App() {

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
      
        <HashRouter>
          <AppRouter />
          </HashRouter>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;
