import logo from "../src/images/logo.png"
import solidappmaker from "./images/solidappmaker.png"
import { useState ,useEffect} from "react"
import { Link } from "react-router-dom";
import { privacy_dispatch } from "../src/redux/action/landingPage"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
function PrivacyPolicy() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
    const [lastClickedLink, setLastClickedLink] = useState(null);
    const aboutData = useSelector((state) => state?.data?.privacy_terms_data?.privacy_policy )
    const privacyPolicy_data = () => {
        const successCb = (res) => {
            // console.log("succCb", res)
        }
        const failureCb = (res) => {
            // console.log("failCbMyprfile", res)
        }
        dispatch(privacy_dispatch(successCb, failureCb))
    }
    useEffect(() => {
        privacyPolicy_data()

    }, [])

    return (
        <>
            {/* navbar */}
            <nav class="navbar navbar-expand-md bg-white navbar-dark boxshadowNav">
                <div class="container">

                    <a class="navbar-brand" >
                        <div className="logo">
                            <img src={logo} className="responsive"></img>
                        </div>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end " id="collapsibleNavbar">

                        <ul className="navbar-nav">
                            <li className={`nav-item mx-3 ${!lastClickedLink || lastClickedLink === 'home' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="/"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { localStorage.setItem("changesPage", "home"); navigate('/') }}
                                >
                                    Home
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'about' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="/"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { localStorage.setItem("changesPage", "about"); navigate('/') }}
                                >
                                    Mission Statement
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'why' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="/"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { localStorage.setItem("changesPage", "why"); navigate('/') }}
                                >
                                    Why Us
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'Services' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="/"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { localStorage.setItem("changesPage", "Services"); navigate('/') }}
                                >
                                    Services
                                </Link>
                            </li>
                            <li className={`nav-item mx-3 ${lastClickedLink === 'contact' ? 'active' : ''}`}>

                                <Link
                                    className="nav-link"
                                    to="/"
                                    // onClick={() => setLastClickedLink('home')}
                                    onClick={() => { localStorage.setItem("changesPage", "contact"); navigate('/') }}
                                >
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* first container */}

            <div >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center ">
                            <div className="container paddingMision">
                                <div className="about_heading  fixedheight">
                                    <h6 className="about heading_why text-center">Privacy Policy</h6>
                                    <p className="p content_why" dangerouslySetInnerHTML={{ __html: aboutData }} >
                                      
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            {/* footer */}
            <div className="container">
                <div className="footerimg">
                    <img src={logo}></img>
                </div>
                <div className="row nav_bottom">
                    <div className="col-md-1">
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link " href="#home">Home</a> */}
                            <Link
                                className="nav-link"
                                to="/"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { localStorage.setItem("changesPage", "home"); navigate('/') }}
                            >
                                Home
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link" href="#MissionStatement">  Mission Statement</a> */}
                            <Link
                                className="nav-link"
                                to="/"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { localStorage.setItem("changesPage", "about"); navigate('/') }}
                            >
                                Mission Statement
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link" href="#why">Why Us</a> */}
                            <Link
                                className="nav-link"
                                to="/"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { localStorage.setItem("changesPage", "why"); navigate('/') }}
                            >
                                Why Us
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer">
                            {/* <a class="nav-link" href="#Services">Services</a> */}
                            <Link
                                className="nav-link"
                                to="/"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { localStorage.setItem("changesPage", "Services"); navigate('/') }}
                            >
                                Services
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2 contactspace">
                        <div className="footer">
                            {/* <a class="nav-link" href="#contact">Contact Us</a> */}
                            <Link
                                className="nav-link"
                                to="/"
                                // onClick={() => setLastClickedLink('home')}
                                onClick={() => { localStorage.setItem("changesPage", "contact"); navigate('/') }}
                            >
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>


            <div className="parent_footer">

                <div className="footer_text">
                    <div className="d-flex justify-content-center py-3"> © 2022 - All Rights Reserved - A1 Truck Parking  | &nbsp;<Link className="text-white" > Privacy Policy </Link >&nbsp; and  &nbsp;<Link className="text-white" to="/TermsConditions"> Terms of Use</Link> &nbsp;| Designed & Developed by
                        &nbsp;<div className="solidappmaker" >
                            <a href="https://solidappmaker.com/" target="_blank">
                                <img src={solidappmaker}></img></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PrivacyPolicy


