

import 'react-toastify/dist/ReactToastify.css';
import { HashRouter, Route, Routes } from "react-router-dom";   
import LandingPage from "../landingpage";
import PrivacyPolicy from '../privacyPolicy';
import TermsConditions from '../terms';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet"
const AppRouter = () => {

    const FetchData = useSelector((state) => state?.data?.about_data?.[0]?.about_truck_parking?.description)
    console.log("FetchData", FetchData)
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>A1 Truck Park</title>
                <link rel="canonical" href="https://a1truckpark.com/static/media/logo.0f6cc5facbbf11ac23a3.png" />
                <meta property="og:image" content="https://a1truckpark.com/static/media/logo.0f6cc5facbbf11ac23a3.png" />
                <meta name="description" content= "A1 truck park" />
            </Helmet>

            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/TermsConditions" element={<TermsConditions />} />
            </Routes>



        </>
    );
}
export default AppRouter;
